.top-titlebar {
  border-top: 1px solid grey;
  background: #2c2c2c;
  height: 40px;
  width: 100vw;
  //padding: 10px 12px;

  .title {

    img {
      vertical-align: text-top;
      width: 18px;
    }

    span, button, button:hover {
      color: white;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .btn-openInDesktop {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 3px;
    color: white;
    font-size: 10px;
    height: 24px;
    //right: 35px;
    margin-right: 1rem;

    &:hover {
      background-color: white;
      color: #2c2c2c;
    }
  }

  .header-waffle {
    width: 48px;
    height: 38px;
    border-radius: 0;
    &:hover {
      background-color: #bfbfbf;
    }
  }
}

.password-tip {
  ul {
    list-style-type: none;
    padding-left: 12px;
  }
}

.cancel-button {
	background: #f2f2f2;
	border-radius: 2px;
  border: none;

	&:hover {
		background: #e9e9e9;
	}
}

#top-titlebar {
	border-top: 1px solid grey;
	background: #2c2c2c;
	height: 40px;
	//padding: 10px 12px;

	.title {

		img {
			vertical-align: text-top;
			width: 18px;
		}

		span, button, button:hover {
			color: white;
			font-size: 14px;
			font-weight: 600;
			text-decoration: none;
		}
	}

	.btn-openInDesktop {
		background-color: transparent;
		border: 1px solid white;
		border-radius: 3px;
		color: white;
		font-size: 10px;
		height: 24px;
		//right: 35px;
		margin-right: 1rem;

		&:hover {
			background-color: white;
			color: #2c2c2c;
		}
	}

	.header-waffle {
		width: 48px;
		height: 39px;
		border-radius: 0;
		position: relative;
		&:hover {
			background-color: #bfbfbf;
		}
	}
}

.app-selection {
	width: 100%;
	padding: 2px;
	text-decoration: none !important;

	&:hover {
		background-color: #f2f2f2;
		cursor: pointer;
	}

	&.disabled {
		cursor: default;
		pointer-events: none;
		opacity: .5;
	}

	span {
		color:rgb(50, 49, 48);
	}

	.app-icon {
		width: 48px;
		height: 48px;
		img {
			width: 28px;
			height: 28px;
		}
	}
}

.panel-waffle {
	width: 48px;
	height: 40px;
	border-radius: 0;
	i {
		color: rgb(50, 49, 48);
	}
	&:hover {
		background-color: #e9e9e9;
	}
}