body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-wrapper {
	background-color: #faf9f8;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
  margin-top: 40px;
  height: calc(100% - 40px)
}

.modal-wrapper .title {
		vertical-align: sub;
	}

.modal-wrapper .lead {
  font-weight: 600;
}

.modal-wrapper .form-modal {
  max-width: 480px;
  width: 90%;
}

.scrollable-modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-wrapper .ms-TextField-fieldGroup::before {
  right: 0;
}

.modal-wrapper .required-hidden .ms-TextField-fieldGroup::before {
    display: none
}

img.logo {
	width: 152px;
}

.register {
	font-size: 0.8em;
}

.fake-url {
	color: rgb(16, 110, 190);
}

.fake-url:hover {
		cursor: pointer;
		text-decoration: underline;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
